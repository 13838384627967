/* eslint-disable import/no-anonymous-default-export */
import axios from "configs/axios";

export default {
  pasienbaru: (payload) => axios.post("/pendaftaran/pasienbaru", payload),

  detailspasien: (id, tlahir) =>
    axios.get(`pasien/${id}/${tlahir}`).then((res) => res),
  // detailspasien: (nik) => axios.get(`pasien/${nik}`).then((res) => res),
  pasienlama: (payload) => axios.post("/pendaftaran/pasienlama", payload),
  detailantrean: (nomr, tanggalperiksa) =>
    axios
      .get(`pasien/cariantrean/${nomr}/${tanggalperiksa}`)
      .then((res) => res),
  getjenislayanan: () => axios.get("/pendaftaran/jenislayanan"),
  pasienbarubooking: (payload) =>
    axios.post("/pendaftaran/pasienbarubooking", payload),
};
